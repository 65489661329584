<template>
  <!-- contact-form -->
  <section class="w3l-contact">
    <div class="header-section text-center">
      <h2>联系我们</h2>
      <p class="mt-3 mb-0">我们有7*24小时的电话、电子邮件、微信小程序客服方便您的随时咨询。</p>
    </div>
    <div class="contact-infubd py-5">
      <div class="container py-lg-3">
        <div class="contact-grids row">
          <div class="col-lg-6 contact-left">
            <div class="partners">
              <div class="cont-details">
                <h6 class="mb-4">如需具体的合作或报价，请联系我们。</h6>
              </div>
              <div class="hours">
                <h6 class="mt-4">电子邮箱：</h6>
                <p> <a href="mailto:" style="unicode-bidi:bidi-override;direction:rtl;">
                  knil.pihsrats@zib</a></p>
                <p> <a href="mailto:" style="unicode-bidi:bidi-override;direction:rtl;">
                  knil.pihsrats@troppus</a></p>
                <h6 class="mt-4">地址：</h6>
                <p style="unicode-bidi:bidi-override;direction:rtl;"> 元单1栋1苑一西湾江镇岸井区门斗市海珠省东广</p>
                <h6 class="mt-4">电话：</h6>
                <p class="margin-top"><a href="tel: " style="unicode-bidi:bidi-override;direction:rtl;">
                  3903125-657-68+  </a></p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-lg-0 mt-5 contact-right">
            <form action="#" method="post" class="signin-form">
              <div class="input-grids">
                <div class="form-group">
                  <input type="text" name="w3lName" id="w3lName" placeholder="您的名字*" class="contact-input" />
                </div>
                <div class="form-group">
                  <input type="email" name="w3lSender" id="w3lSender" placeholder="您的电子邮箱*" class="contact-input" />
                </div>
                <div class="form-group">
                  <input type="text" name="w3lSubect" id="w3lSubect" placeholder="标题*" class="contact-input" />
                </div>
              </div>
              <div class="form-group">
                <textarea name="w3lMessage" id="w3lMessage" placeholder="请输入您的留言信息*"></textarea>
              </div>
              <div class="text-right">
                <button class="btn btn-primary theme-button">发送留言</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /contact-form -->
</template>

<script>
export default {
  name: "ContactForm"
}
</script>

<style scoped>

</style>
