<template>
  <div>
    <contact-form />
    <map-content />
  </div>
</template>

<script>
import {
  ContactForm,
  MapContent
} from "./components"

export default {
  name: "Contact",
  components: {
    ContactForm,
    MapContent
  },
  data() {
    return {
      title: '联系 - 珠海市星舰科技有限公司'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      document.title = this.title
    }
  }
}
</script>

<style scoped>

</style>
