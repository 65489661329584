<template>
  <div class="w3l-contact contact-infubd py-5 container py-lg-3">
    <div class="map mt-5 pt-md-5">
      <h5>公司位置</h5>
      <!--百度地图容器-->
      <div style="width:640px;height:320px;border:#ccc solid 1px;" id="dituContent"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapContent"
}
</script>

<style scoped>

</style>
